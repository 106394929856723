// Loader.tsx
import React, { useEffect } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import NProgress from "nprogress"; // Must import nprogress
import "nprogress/nprogress.css"; // And its default CSS
import { Spin } from "antd";

const Loader = ({
  children,
  isLoading,
  isOverlayLoading = false,
  styles,
}: {
  children?: React.ReactNode;
  isLoading?: boolean;
  isOverlayLoading?: boolean;
  styles?: any;
}) => {
  useEffect(() => {
    if (isLoading) {
      NProgress.start();
    } else {
      NProgress.done();
    }
  }, [isLoading]);
  return (
    <LoadingOverlay
      active={isOverlayLoading ? isOverlayLoading : isLoading}
      spinner={isOverlayLoading ? <Spin /> : false}
      styles={styles}
    >
      {children}
    </LoadingOverlay>
  );
};

export default Loader;
