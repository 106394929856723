/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Table from "./Table";
import Loader from "./Loader";
import { getIncomingShipmentList } from "../redux/authSlice";

const ProgressLine = ({
  backgroundColor = "#b3b3b3",
  visualParts = [
    {
      percentage: "0%",
      value: 0,
      color: "white",
    },
  ],
}) => {
  const [widths, setWidths] = useState(
    visualParts.map(() => {
      return 0;
    })
  );
  useEffect(() => {
    requestAnimationFrame(() => {
      setWidths(
        visualParts.map((item: any) => {
          return item.percentage;
        })
      );
    });
  }, [visualParts]);
  return (
    <>
      <Tooltip
        placement="bottom"
        title={
          <div>
            {visualParts &&
              visualParts.length > 0 &&
              // eslint-disable-next-line
              visualParts.map((item) => {
                if (item.color === "#57bb54") {
                  return (
                    <h1 className="text-white">
                      Received Quantity: {item.value}
                    </h1>
                  );
                } else if (item.color === "#e11f20") {
                  return (
                    <h1 className="text-white">
                      Rejected Quantity: {item.value}
                    </h1>
                  );
                } else if (item.color === "#b3b3b3") {
                  return (
                    <h1 className="text-white">
                      Remaining Quantity: {item.value}
                    </h1>
                  );
                }
              })}
          </div>
        }
      >
        <div
          className="progressVisualFull"
          style={{
            backgroundColor,
            borderRadius: 10,
            height: 15,
            overflow: "hidden",
          }}
        >
          {visualParts.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  width: widths[index],
                  backgroundColor: item.color,
                }}
                className="progressVisualPart"
              />
            );
          })}
        </div>
      </Tooltip>
    </>
  );
};

const IncomingShipmentListing = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [incomingShipmentsPageIndex, setIncomingShipmentsPageIndex] =
    useState<any>("1");
  const [incomingshipmentList, setIncomingshipmentList] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [recordCount, setRecordCount] = useState("");
  const [incomingShipmentsPageMaxIndex, setIncomingShipmentsPageMaxIndex] =
    useState<any>("");
  const [sortData, setSortData] = useState<any>([]);
  const [sortedInfo, setSortedInfo] = useState<any>();
  const [pageSize, setPageSize] = useState<any>();
  const [searchedQuery, setSearchedQuery] = useState("");
  const [isSearch, setIsSearch] = useState<any>("");
  const [searchableString, setSearchableString] = useState<any>([]);
  useEffect(() => {
    const fetchData = async () => {
      let prevIncomingListing = localStorage.getItem(
        "prevIncomingShipmentListing"
      );
      let passedIndexShipmentListing = localStorage.getItem(
        "passedIndexIncomingShipmentListing"
      );
      let prevShipmentListing: any;
      if (localStorage.getItem("sortedInfoShipmentListing")) {
        prevShipmentListing = localStorage.getItem("sortedInfoShipmentListing");
        prevShipmentListing = JSON.parse(prevShipmentListing);
        // setSortedInfo(prevShipmentListing);
      }
      setIsLoading(true);
      if (isSearch === "Searched") {
        await dispatch(
          getIncomingShipmentList({
            setIncomingshipmentList,
            orderByFields: sortData,
            queryString: searchableString,
            setRecordCount,
            setIncomingShipmentsPageMaxIndex,
            pageIndex: passedIndexShipmentListing
              ? passedIndexShipmentListing
              : 0,
          })
        );
      } else {
        await dispatch(
          getIncomingShipmentList({
            setIncomingshipmentList,
            orderByFields: sortData,
            setRecordCount,
            setIncomingShipmentsPageMaxIndex,
            pageIndex: passedIndexShipmentListing
              ? passedIndexShipmentListing
              : 0,
          })
        );
      }
      setIsLoading(false);
    };
    fetchData();
  }, [location.key]); // eslint-disable-line
  const incomingShipmentNextSet = async (
    pageIndex: any,
    orderByFields: any,
    pageSize?: any
  ) => {
    setIsTableLoading(true);
    await dispatch(
      getIncomingShipmentList({
        setIncomingshipmentList,
        orderByFields,
        setRecordCount,
        setIncomingShipmentsPageMaxIndex,
        pageIndex,
        pageSize,
      })
    );

    setIsTableLoading(false);
  };
  const loadNextProds = (index: any, pageSize: any) => {
    if (!incomingShipmentsPageIndex || incomingShipmentsPageIndex * 1 === 0) {
      return;
    }
    if (incomingShipmentsPageIndex * 1 > incomingShipmentsPageMaxIndex) {
      return;
    }
    const passedIndex: any = index * 1 - 1;
    setIncomingShipmentsPageIndex(index);
    localStorage.setItem("passedIndexIncomingShipmentListing", passedIndex);
    let orderByFields = sortData;
    if (pageSize > 10) {
      setPageSize(pageSize);
    }
    incomingShipmentNextSet(passedIndex, orderByFields, pageSize);
  };
  const columns = [
    {
      title: (
        <div className="flex items-center relative h-5">
          <p className="mr-1">ID</p>
        </div>
      ),
      dataIndex: "shipmentId",
      sortOrder: sortedInfo?.shipmentId ? sortedInfo.shipmentId : null,
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.shipmentId === "number") {
            return a.shipmentId - b.shipmentId;
          } else {
            return a.shipmentId?.localeCompare(b.shipmentId);
          }
        },
        multiple: 1,
      },
      render: (shipmentId: string, data: any) => (
        <Link
          to={{
            pathname: `/inventory/${shipmentId}`,
            state: { shipmentId, entryDate: data.entryDate },
          }}
        >
          <h1 className="cursor-pointer underline text-primary">
            {shipmentId}
          </h1>
        </Link>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5">
          <p className="mr-1">PO (Order Number)</p>
        </div>
      ),
      dataIndex: "otherPartyOrderId",
      sortOrder: sortedInfo?.otherPartyOrderId
        ? sortedInfo.otherPartyOrderId
        : null,
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.otherPartyOrderId === "number") {
            return a.otherPartyOrderId - b.otherPartyOrderId;
          } else {
            return a.otherPartyOrderId?.localeCompare(b.otherPartyOrderId);
          }
        },
        multiple: 1,
      },
      render: (otherPartyOrderId: string, data: any) => (
        <h1 className="cursor-pointer">{otherPartyOrderId}</h1>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5">
          <p className="mr-1">Date Created</p>
        </div>
      ),
      dataIndex: "entryDate",
      sortOrder: sortedInfo?.entryDate ? sortedInfo?.entryDate : null,
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.entryDate === "number") {
            return a.entryDate - b.entryDate;
          } else {
            return a.entryDate?.localeCompare(b.entryDate);
          }
        },
        multiple: 1,
      },
      render: (entryDate: any) => {
        function dateCon(date: any) {
          const data = new Date(date);
          const monthArr = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          const fullYear = data.getFullYear();
          const fullMonth = data.getMonth();
          const fullDate = data.getDate();
          return `${monthArr[fullMonth]} ${fullDate}, ${fullYear}`;
        }
        return (
          <div className="flex flex-row items-center">
            <h1 className="">{entryDate ? dateCon(entryDate) : ""}</h1>
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5">
          <p className="mr-1">Status</p>
        </div>
      ),
      dataIndex: "status",
      sortOrder: sortedInfo?.status ? sortedInfo?.status : null,
      sorter: {
        compare: (a: any, b: any) => {
          if (typeof a.status === "number") {
            return a.status - b.status;
          } else {
            return a.status?.localeCompare(b.status);
          }
        },
        multiple: 1,
      },
      render: (status: string) => <h1 className="cursor-pointer">{status}</h1>,
    },
    {
      title: (
        <div className="flex items-center relative h-5">
          <p className="mr-1">Progress Bar</p>
        </div>
      ),
      dataIndex: `Progressbar`,
      render: (Progressbar: any) => {
        let receivedQuantity: any =
          (Progressbar?.receivedQuantity / Progressbar?.expectedQuantity) * 100;
        receivedQuantity = Math.round(receivedQuantity * 100) / 100 + "%";
        let rejectedQuantity: any =
          (Progressbar?.rejectedQuantity / Progressbar?.expectedQuantity) * 100;
        rejectedQuantity = Math.round(rejectedQuantity * 100) / 100 + "%";
        let remainingQuantity: any =
          (Progressbar?.remainingQuantity / Progressbar?.expectedQuantity) *
          100;
        remainingQuantity = Math.round(remainingQuantity * 100) / 100 + "%";
        return (
          <div>
            <ProgressLine
              visualParts={[
                {
                  percentage: receivedQuantity,
                  value: Progressbar?.receivedQuantity,
                  color: "#57bb54",
                },
                {
                  percentage: rejectedQuantity,
                  value: Progressbar?.rejectedQuantity,
                  color: "#e11f20",
                },
                {
                  percentage: remainingQuantity,
                  value: Progressbar?.remainingQuantity,
                  color: "#b3b3b3",
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
  function onChange(pagination: any, filters: any, sorter: any, extra: any) {
    let outputStr = "";
    setSortData("");
    if (Object.keys(sorter)?.length > 0) {
      Object.keys(sorter).forEach((item: any) => {
        if (item === "order" && sorter[item] === "ascend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              outputStr += `,${sorter["field"]}`;
            }
          } else {
            outputStr = `${sorter["field"]}`;
          }
        } else if (item === "order" && sorter[item] === "descend") {
          if (outputStr) {
            if (outputStr.includes(sorter["field"])) {
              // do nothing
            } else {
              outputStr += `,-${sorter["field"]}`;
            }
          } else {
            outputStr = `-${sorter["field"]}`;
          }
        }
      });
    }
    sorter &&
      sorter.length > 0 &&
      sorter?.forEach((item: any) => {
        if (item.order === "ascend") {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              outputStr += `,${item.field}`;
            }
          } else {
            outputStr = `${item.field}`;
          }
        } else {
          if (outputStr) {
            if (outputStr.includes(item["field"])) {
              // do nothing
            } else {
              outputStr += `,-${item.field}`;
            }
          } else {
            outputStr = `-${item.field}`;
          }
        }
      });
    setSortData((prev: any) => {
      if (prev) {
        return prev + `,${outputStr}`;
      } else {
        return outputStr;
      }
    });
    const myArray = outputStr.split(",");
    let shipmentId: any;
    let entryDate: any;
    let status: any;
    let otherPartyOrderId: any;
    myArray.forEach((shipmentItem) => {
      if (shipmentItem === "shipmentId") {
        shipmentId = "ascend";
      } else if (shipmentItem === "-shipmentId") {
        shipmentId = "descend";
      } else if (shipmentItem === "entryDate") {
        entryDate = "ascend";
      } else if (shipmentItem === "-entryDate") {
        entryDate = "descend";
      } else if (shipmentItem === "status") {
        status = "ascend";
      } else if (shipmentItem === "-status") {
        status = "descend";
      } else if (shipmentItem === "otherPartyOrderId") {
        otherPartyOrderId = "ascend";
      } else if (shipmentItem === "-otherPartyOrderId") {
        otherPartyOrderId = "descend";
      }
      localStorage.setItem(
        "sortedInfoShipmentListing",
        JSON.stringify({
          shipmentId: shipmentId,
          entryDate: entryDate,
          status: status,
          otherPartyOrderId: otherPartyOrderId,
        })
      );
      setSortedInfo({
        shipmentId: shipmentId,
        entryDate: entryDate,
        status: status,
        otherPartyOrderId: otherPartyOrderId,
      });
    });
    localStorage.setItem("prevIncomingShipmentListing", outputStr);
    const passedIndex = incomingShipmentsPageIndex * 1 - 1;
    if (recordCount) {
      incomingShipmentNextSet(passedIndex, outputStr, pageSize);
    }
  }
  return (
    <div className="h-screen">
      <div>
        <Loader
          isLoading={isLoading}
          styles={{
            overlay: (base: any) => ({
              ...base,
              backgroundColor: "",
            }),
          }}
        >
          <div className="search-prod w-full ltmob:w-4/6 mdlarge:w-3/5 mdxlarge:w-2/5 mb-8">
            <span className="search-icon">
              <SearchOutlined style={{ fontSize: 17 }} />
            </span>
            <input
              placeholder="Search POs"
              defaultValue={searchedQuery}
              value={searchedQuery}
              className="border border-gray-400 px-2 py-2 w-full rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light"
              onKeyPress={async (e: any) => {
                if (e.key === "Enter") {
                  setIsTableLoading(true);
                  let passedIndexShipmentListing = localStorage.getItem(
                    "passedIndexIncomingShipmentListing"
                  );
                  setIsSearch("Searched");
                  await dispatch(
                    getIncomingShipmentList({
                      setIncomingshipmentList,
                      orderByFields: sortData,
                      setRecordCount,
                      setIncomingShipmentsPageMaxIndex,
                      pageIndex: 0,
                      pageSize,
                      searchQuery: e.target.value,
                    })
                  );
                  setSearchedQuery("");
                  setSearchableString(e.target.value);
                  setIsTableLoading(false);
                }
              }}
              onChange={async (e: any) => {
                setSearchedQuery(e.target.value);
              }}
            />
          </div>
          <div className="bg-white p-5 pb-0.5 rounded-lg shadow-sm">
            <div className="flex flex-row justify-between">
              <div className="ltmob:flex ltmob:flex-row m-3 ltmob:items-center">
                <div className="flex flex-row items-center pb-6">
                  <button
                    onClick={async () => {
                      setIsTableLoading(true);
                      let passedIndexShipmentListing = localStorage.getItem(
                        "passedIndexIncomingShipmentListing"
                      );
                      await dispatch(
                        getIncomingShipmentList({
                          setIncomingshipmentList,
                          orderByFields: sortData,
                          queryString: searchableString,
                          setRecordCount,
                          setIncomingShipmentsPageMaxIndex,
                          pageIndex: passedIndexShipmentListing
                            ? passedIndexShipmentListing
                            : 0,
                          pageSize,
                        })
                      );
                      setSearchedQuery("");
                      setIsTableLoading(false);
                    }}
                    className="px-8 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-2 rounded-lg"
                  >
                    Clear Filters
                  </button>
                  <button
                    onClick={async () => {
                      setIsTableLoading(true);
                      let passedIndexShipmentListing = localStorage.getItem(
                        "passedIndexIncomingShipmentListing"
                      );
                      await dispatch(
                        getIncomingShipmentList({
                          setIncomingshipmentList,
                          orderByFields: sortData,
                          queryString: searchableString,
                          setRecordCount,
                          setIncomingShipmentsPageMaxIndex,
                          pageIndex: passedIndexShipmentListing
                            ? passedIndexShipmentListing
                            : 0,
                          pageSize,
                        })
                      );
                      setSearchedQuery("");
                      setSortedInfo({});
                      setSortData("");
                      setIsTableLoading(false);
                    }}
                    className="ml-4 px-8 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-2 rounded-lg"
                  >
                    Clear Sorter
                  </button>
                </div>
              </div>
            </div>
            <Table
              columns={columns}
              dataSource={incomingshipmentList}
              isPaginated={true}
              isServerRendered={recordCount ? true : false}
              rowKey="shipmentId"
              loadNextProds={loadNextProds}
              recordCount={recordCount}
              loading={isTableLoading}
              onChange={onChange}
            />
          </div>
        </Loader>
      </div>
    </div>
  );
};

export default IncomingShipmentListing;
