/* eslint-disable */
import React, { useState, useEffect, memo } from "react";
import { useDispatch } from "react-redux";
import {
  CalendarOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { AiOutlineFilter } from "react-icons/ai";
import { BsDash } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
// @ts-ignore
import { useMediaQuery } from "react-responsive";
import moment from "moment";

import Table from "./Table";
import Loader from "./Loader";
import Input from "./Input";
import DatePicker from "./DatePicker";
import {
  getEnumTypeList,
  getImportListing,
  getStatusList,
  getTemplatesList,
} from "../redux/authSlice";

const FilterSearchBar = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}: {
  setSelectedKeys: any;
  selectedKeys: any;
  confirm: any;
  clearFilters: any;
}) => {
  return (
    <div style={{ padding: 8 }}>
      <div>
        <Input
          title=""
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e: any) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              confirm();
            }
          }}
          onBlur={confirm}
        />
        <div className="mt-3 flex flex-row items-center justify-between">
          <button
            onClick={clearFilters}
            className="px-5 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-1 rounded-lg"
          >
            Reset
          </button>
          <button
            onClick={() => {
              document
                .getElementsByClassName("ant-dropdown")[0]
                .classList.add("ant-dropdown-hidden");
              confirm();
            }}
            className="px-5 text-xs sm:px-3 sm:text-sm bg-primary focus:outline-none border text-white font-semibold py-1 rounded-lg"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

const ImportListing = memo(
  ({
    fromDate,
    thruDate,
    facilityId,
    statusId,
    queryString,
    importType,
    showLoader = true,
    shouldRerender,
    setShouldRerender,
    shipmentId,
    setErrorFileNotification,
    errorFileNotification,
  }: {
    fromDate?: any;
    thruDate?: any;
    facilityId?: any;
    statusId?: any;
    queryString?: any;
    importType?: any;
    showLoader?: any;
    shouldRerender?: any;
    setShouldRerender?: any;
    shipmentId?: any;
    setErrorFileNotification?: any;
    errorFileNotification?: any;
  }) => {
    const isMobileDevice = useMediaQuery({
      query: "(max-device-width: 638px)",
    });
    const dispatch = useDispatch();
    const location = useLocation();
    const [currIndex, setCurrIndex] = useState<any>("1");
    const [isLoading, setIsLoading] = useState(false);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [getImportListingDetail, setgetImportListingDetail] =
      useState<any>("");
    const [recordCount, setRecordCount] = useState("");
    const [maxPageIndex, setMaxPageIndex] = useState<any>("");
    const [isSearch, setIsSearch] = useState<any>("");
    const [searchableString, setSearchableString] = useState<any>([]);
    const [sortData, setSortData] = useState<any>("");
    const [templateList, setTemplateList] = useState([]);
    const [activeAppliedFilters, setActiveAppliedFilters] = useState("");
    const [selectedFromDate, setSelectedFromDate] = useState(
      fromDate
        ? fromDate
        : localStorage.getItem("importListingFromDate")
        ? localStorage.getItem("importListingFromDate")
        : ""
    );
    const [selectedThruDate, setSelectedThruDate] = useState(
      thruDate
        ? thruDate
        : localStorage.getItem("importListingThruDate")
        ? localStorage.getItem("importListingThruDate")
        : ""
    );
    const [filteredInfo, setFilteredInfo] = useState<any>({});
    const [sortedInfo, setSortedInfo] = useState<any>({});
    const [datePickerFrom, setDatePickerFrom] = useState<any>(
      fromDate
        ? moment(fromDate, "YYYY-MM-DD")
        : localStorage.getItem("importListingFromDate")
        ? moment(localStorage.getItem("importListingFromDate"), "YYYY-MM-DD")
        : ""
    );
    const [datePickerThru, setDatePickerThru] = useState<any>(
      thruDate
        ? moment(thruDate, "YYYY-MM-DD")
        : localStorage.getItem("importListingThruDate")
        ? moment(localStorage.getItem("importListingThruDate"), "YYYY-MM-DD")
        : ""
    );
    const [searchedQuery, setSearchedQuery] = useState("");
    const [resetInputField, setResetInputField] = useState<any>(false);
    const [pageSize, setPageSize] = useState<any>();
    const salesChannelFilters: any = [];
    const statusFilters: any = [];
    const shipMethodFilters: any = [];
    useEffect(() => {
      fetchData();
      return () => {
        setResetInputField(false);
      };
    }, [location.key]); // eslint-disable-line
    console.log("sortedInfo----- in 175-------->", sortedInfo);
    useEffect(() => {
      if (shouldRerender) {
        fetchData();
        setShouldRerender(false);
      }
    }, [shouldRerender]); // eslint-disable-line
    const fetchData = async () => {
      if (fromDate) {
        localStorage.setItem("importListingFromDate", fromDate);
      }
      if (thruDate) {
        localStorage.setItem("importListingThruDate", thruDate);
      }
      let prevAppliedFilter: any = localStorage.getItem("prevAppliedFilter");
      if (!prevAppliedFilter) {
        prevAppliedFilter = {
          appliedFilters: importType ? `templateIdList=${importType}` : "",
        };
        setActiveAppliedFilters(prevAppliedFilter?.appliedFilters);
      } else {
        prevAppliedFilter = JSON.parse(prevAppliedFilter);
        prevAppliedFilter = {
          ...prevAppliedFilter,
          appliedFilters: importType ? `templateIdList=${importType}` : "",
        };
        console.log("125", prevAppliedFilter);
        setSortedInfo(prevAppliedFilter?.sortedInfo);
        setFilteredInfo(prevAppliedFilter?.filteredInfo);
        setActiveAppliedFilters(prevAppliedFilter?.appliedFilters);
      }
      setIsLoading(true);
      if (isSearch === "Searched") {
        await dispatch(
          getImportListing({
            setgetImportListingDetail,
            setMaxPageIndex,
            orderByFields: " ",
            setRecordCount,
            pageIndex: 0,
            pageSize,
            queryString: searchableString,
            appliedFilters: "",
            shipmentId,
            setErrorFileNotification,
            errorFileNotification,
          })
        );
      } else {
        await dispatch(
          getImportListing({
            setgetImportListingDetail,
            setMaxPageIndex,
            orderByFields: prevAppliedFilter?.orderByFields
              ? prevAppliedFilter?.orderByFields
              : sortData,
            setRecordCount,
            pageIndex: localStorage.getItem("currPageIndex")
              ? localStorage.getItem("currPageIndex")
              : 0,
            pageSize,
            // searchString: orderStatus,
            uploadedOn_from: selectedFromDate
              ? selectedFromDate
              : fromDate
              ? fromDate
              : "",
            uploadedOn_thru: selectedThruDate
              ? selectedThruDate
              : thruDate
              ? thruDate
              : "",
            statusId,
            facilityId: facilityId ? facilityId : "",
            queryString,
            appliedFilters: prevAppliedFilter?.appliedFilters
              ? prevAppliedFilter.appliedFilters
              : "",
            shipmentId,
            setErrorFileNotification,
            errorFileNotification,
          })
        );
        await dispatch(getTemplatesList({ setTemplateList }));
      }
      setIsLoading(false);
    };
    const nextSetOfProds = async (
      pageIndex: any,
      orderByFields: any,
      appliedFilters: any,
      resetFields?: any,
      pageSize?: any
    ) => {
      setIsTableLoading(true);
      console.log("appliedFilters---198-----", appliedFilters);
      console.log("inside the nextSetOfProds", pageIndex);
      localStorage.setItem("currPageIndex", pageIndex);
      if (resetFields) {
        setResetInputField(true);
      } else {
        setResetInputField(false);
      }
      if (isSearch === "Searched") {
        await dispatch(
          getImportListing({
            setgetImportListingDetail,
            setMaxPageIndex,
            orderByFields,
            setRecordCount,
            pageIndex,
            pageSize,
            queryString: searchableString,
            uploadedOn_from: selectedFromDate
              ? selectedFromDate
              : fromDate
              ? fromDate
              : "",
            uploadedOn_thru: selectedThruDate
              ? selectedThruDate
              : thruDate
              ? thruDate
              : "",
            statusId,
            facilityId: facilityId ? facilityId : "",
            appliedFilters,
            shipmentId,
          })
        );
      } else {
        await dispatch(
          getImportListing({
            setgetImportListingDetail,
            setMaxPageIndex,
            orderByFields,
            setRecordCount,
            pageIndex,
            pageSize,
            //searchString: orderStatus,
            uploadedOn_from: selectedFromDate
              ? selectedFromDate
              : fromDate
              ? fromDate
              : "",
            uploadedOn_thru: selectedThruDate
              ? selectedThruDate
              : thruDate
              ? thruDate
              : "",
            statusId,
            queryString,
            facilityId: facilityId ? facilityId : "",
            appliedFilters,
            shipmentId,
          })
        );
      }
      setIsTableLoading(false);
    };
    const loadNextProds = (index: any, pageSize: any) => {
      console.log("line-249----", index);
      if (!currIndex || currIndex * 1 === 0) {
        return;
      }
      if (currIndex * 1 > maxPageIndex) {
        return;
      }
      const passedIndex = index * 1 - 1;
      setCurrIndex(index);
      console.log("255", passedIndex);
      console.log("256", index);
      let prevAppliedFilter: any = localStorage.getItem("prevAppliedFilter");

      prevAppliedFilter = JSON.parse(prevAppliedFilter);
      let orderByFields = sortData;
      if (prevAppliedFilter?.orderByFields) {
        orderByFields = prevAppliedFilter?.orderByFields;
      }

      console.log("orderByFields in loadNextProds", orderByFields);
      if (pageSize > 10) {
        setPageSize(pageSize);
      }
      nextSetOfProds(
        passedIndex,
        orderByFields,
        activeAppliedFilters,
        false,
        pageSize
      );
    };

    const columns = [
      {
        title: (
          <div className="flex items-center relative h-5 p-2 leading-3">
            <p className="mr-1">Data Import ID</p>
          </div>
        ),
        dataIndex: "dataImportId",
        // filterDropdown: ({
        //   setSelectedKeys,
        //   selectedKeys,
        //   confirm,
        //   clearFilters,
        // }: {
        //   setSelectedKeys: any;
        //   selectedKeys: any;
        //   confirm: any;
        //   clearFilters: any;
        // }) => (
        //   <FilterSearchBar
        //     setSelectedKeys={setSelectedKeys}
        //     selectedKeys={selectedKeys}
        //     confirm={confirm}
        //     clearFilters={clearFilters}
        //   />
        // ),
        filteredValue: filteredInfo?.dataImportId || null,
        onFilter: (value: any, record: any) =>
          record?.dataImportId?.includes(value),
        filterIcon: () => {
          return filteredInfo.dataImportId ? (
            <AiOutlineFilter size={17} color="#093479" />
          ) : (
            <AiOutlineFilter size={17} />
          );
        },
        sorter: {
          compare: (a: any, b: any) => {
            if (typeof a.dataImportId === "number") {
              return a.dataImportId - b.dataImportId;
            } else {
              return a.dataImportId?.localeCompare(b.dataImportId);
            }
          },
          multiple: 1,
        },
        sortOrder:
          sortedInfo?.dataImportId === "dataImportId" &&
          sortedInfo?.dataImportIdType,
        render: (dataImportId: string) => (
          <h1 className="px-2">{dataImportId}</h1>
        ),
      },
      {
        title: (
          <div className="flex items-center relative h-5 p-2 leading-3">
            <p className="mr-1">Date Uploaded</p>
          </div>
        ),
        // filterDropdown: ({
        //   setSelectedKeys,
        //   selectedKeys,
        //   confirm,
        //   clearFilters,
        // }: {
        //   setSelectedKeys: any;
        //   selectedKeys: any;
        //   confirm: any;
        //   clearFilters: any;
        // }) => (
        //   <div style={{ padding: 8 }}>
        //     <div>
        //       <div className="sm:flex sm:flex-row sm:items-center">
        //         <DatePicker
        //           title="From"
        //           value={datePickerFrom}
        //           onChange={(date: any, dateString: any) => {
        //             setDatePickerFrom(date);
        //             setSelectedFromDate(dateString);
        //             localStorage.setItem("importListingFromDate", dateString);
        //           }}
        //         />
        //         {!isMobileDevice && <BsDash className="mt-5 mx-0.5" />}
        //         <div className="mt-3 sm:mt-0">
        //           <DatePicker
        //             title="Thru"
        //             value={datePickerThru}
        //             onChange={(date: any, dateString: any) => {
        //               setDatePickerThru(date);
        //               setSelectedThruDate(dateString);
        //               localStorage.setItem("importListingThruDate", dateString);
        //             }}
        //           />
        //         </div>
        //       </div>
        //       <div className="mt-3 flex flex-row items-center justify-between">
        //         <button
        //           onClick={async () => {
        //             setIsLoading(true);
        //             await dispatch(
        //               getImportListing({
        //                 setgetImportListingDetail,
        //                 setMaxPageIndex,
        //                 orderByFields: sortData,
        //                 setRecordCount,
        //                 pageIndex: 0,
        //                 // searchString: orderStatus,
        //                 uploadedOn_from: fromDate ? fromDate : "",
        //                 uploadedOn_thru: thruDate ? thruDate : "",
        //                 statusId,
        //                 queryString,
        //                 facilityId: facilityId ? facilityId : "",
        //                 appliedFilters: activeAppliedFilters,
        //               })
        //             );
        //             setResetInputField(true);
        //             setSelectedFromDate("");
        //             setSelectedThruDate("");
        //             setDatePickerFrom("");
        //             setDatePickerThru("");
        //             filteredInfo["uploadedOn"] = null;
        //             clearFilters();
        //             setIsLoading(false);
        //           }}
        //           className="px-5 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-1 rounded-lg"
        //         >
        //           Reset
        //         </button>
        //         <button
        //           onClick={async () => {
        //             if (!selectedFromDate && !selectedThruDate) {
        //               return;
        //             }
        //             setIsLoading(true);
        //             document
        //               .getElementsByClassName("ant-dropdown")[0]
        //               .classList.add("ant-dropdown-hidden");
        //             await dispatch(
        //               getImportListing({
        //                 setgetImportListingDetail,
        //                 setMaxPageIndex,
        //                 orderByFields: sortData,
        //                 setRecordCount,
        //                 pageIndex: 0,
        //                 // searchString: orderStatus,
        //                 uploadedOn_from: selectedFromDate,
        //                 uploadedOn_thru: selectedThruDate,
        //                 statusId,
        //                 queryString,
        //                 facilityId: facilityId ? facilityId : "",
        //                 appliedFilters: activeAppliedFilters,
        //               })
        //             );
        //             setResetInputField(true);
        //             filteredInfo["uploadedOn"] = "uploadedOn";
        //             setIsLoading(false);
        //           }}
        //           className="px-5 text-xs sm:px-3 sm:text-sm bg-primary focus:outline-none border text-white font-semibold py-1 rounded-lg"
        //         >
        //           Filter
        //         </button>
        //       </div>
        //     </div>
        //   </div>
        // ),
        dataIndex: "uploadedOn",
        filterIcon: () => {
          return filteredInfo.uploadedOn ? (
            <AiOutlineFilter size={17} color="#093479" />
          ) : (
            <AiOutlineFilter size={17} />
          );
        },
        sorter: {
          compare: (a: any, b: any) => {
            if (typeof a.uploadedOn === "number") {
              return a.uploadedOn - b.uploadedOn;
            } else {
              return a.uploadedOn?.localeCompare(b.uploadedOn);
            }
          },
          multiple: 1,
        },
        sortOrder:
          sortedInfo?.uploadedOn === "uploadedOn" && sortedInfo?.uploadedOnType,
        render: (uploadedOn: any) => {
          function dateCon(date: any) {
            const data = new Date(date).toDateString();
            return data;
          }
          return (
            <div className="flex flex-row items-center">
              {uploadedOn ? (
                <CalendarOutlined style={{ fontSize: 18, color: "#999" }} />
              ) : (
                ""
              )}

              <h1 className="ml-1.5">
                {uploadedOn ? dateCon(uploadedOn) : ""}
              </h1>
            </div>
          );
        },
      },
      {
        title: (
          <div className="flex items-center relative h-5 p-2 leading-3">
            <p className="mr-1">FileName</p>
          </div>
        ),
        dataIndex: "fileName",
        // filterDropdown: ({
        //   setSelectedKeys,
        //   selectedKeys,
        //   confirm,
        //   clearFilters,
        // }: {
        //   setSelectedKeys: any;
        //   selectedKeys: any;
        //   confirm: any;
        //   clearFilters: any;
        // }) => (
        //   <FilterSearchBar
        //     setSelectedKeys={setSelectedKeys}
        //     selectedKeys={selectedKeys}
        //     confirm={confirm}
        //     clearFilters={clearFilters}
        //   />
        // ),
        filterIcon: () => {
          return filteredInfo?.fileName ? (
            <AiOutlineFilter size={17} color="#093479" />
          ) : (
            <AiOutlineFilter size={17} />
          );
        },
        sorter: {
          compare: (a: any, b: any) => {
            if (typeof a.fileName === "number") {
              return a.fileName - b.fileName;
            } else {
              return a.fileName?.localeCompare(b.fileName);
            }
          },
          multiple: 1,
        },
        filteredValue: filteredInfo?.fileName || null,
        onFilter: (value: any, record: any) => record?.fileName.includes(value),
        sortOrder:
          sortedInfo?.fileName === "fileName" && sortedInfo?.fileNameType,
        render: (fileName: string) => {
          return (
            <div className="flex flex-row items-center">
              <div className="flex flex-row">
                <h1 className="ml-2">{fileName}</h1>
              </div>
            </div>
          );
        },
      },
      {
        title: (
          <div className="flex items-center relative h-5 p-2 leading-3">
            <p className="mr-1">Status</p>
          </div>
        ),
        dataIndex: "statusId",
        //   filters: statusFilters,
        filterIcon: () => {
          return filteredInfo.statusId ? (
            <AiOutlineFilter size={17} color="#093479" />
          ) : (
            <AiOutlineFilter size={17} />
          );
        },
        sorter: {
          compare: (a: any, b: any) => {
            if (typeof a.statusId === "number") {
              return a.statusId - b.statusId;
            } else {
              return a.statusId?.localeCompare(b.statusId);
            }
          },
          multiple: 1,
        },
        filteredValue: filteredInfo?.statusId || null,
        onFilter: (value: any, record: any) => record?.statusId.includes(value),
        sortOrder:
          sortedInfo?.statusId === "statusId" && sortedInfo?.statusIdType,
        render: (statusId: any) => {
          return (
            <div>
              <h1
                style={{
                  backgroundColor: "#093479",
                  outlineColor: "#707070",
                  border: "1px solid #707070",
                }}
                className="px-1.5 py-0.5 text-center text-white tracking-wider border font-semibold rounded-md w-max"
              >
                {statusId?.toUpperCase()}
              </h1>
            </div>
          );
        },
      },
      {
        title: (
          <div className="flex items-center relative h-5 p-2 leading-3">
            <p className="mr-1">Template</p>
          </div>
        ),
        dataIndex: "templateId",
        // filterDropdown: ({
        //   setSelectedKeys,
        //   selectedKeys,
        //   confirm,
        //   clearFilters,
        // }: {
        //   setSelectedKeys: any;
        //   selectedKeys: any;
        //   confirm: any;
        //   clearFilters: any;
        // }) => (
        //   <FilterSearchBar
        //     setSelectedKeys={setSelectedKeys}
        //     selectedKeys={selectedKeys}
        //     confirm={confirm}
        //     clearFilters={clearFilters}
        //   />
        // ),
        filterIcon: () => {
          return filteredInfo?.templateId ? (
            <AiOutlineFilter size={17} color="#093479" />
          ) : (
            <AiOutlineFilter size={17} />
          );
        },
        sorter: {
          compare: (a: any, b: any) => {
            if (typeof a.templateId === "number") {
              return a.templateId - b.templateId;
            } else {
              return a.templateId?.localeCompare(b.templateId);
            }
          },
          multiple: 1,
        },
        filteredValue: filteredInfo?.templateId || null,
        onFilter: (value: any, record: any) =>
          record?.templateId.includes(value),
        sortOrder:
          sortedInfo?.templateId === "templateId" && sortedInfo?.templateIdType,
        render: (templateId: string) => {
          const templateName: any = templateList?.find(
            (template: any) => template?.templateId === templateId
          );
          return (
            <div className="flex flex-row items-center">
              <div className="flex flex-row">
                <h1 className="ml-2">{templateName?.templateName}</h1>
              </div>
            </div>
          );
        },
      },
      {
        title: (
          <div className="flex items-center relative h-5 p-2 leading-3">
            <p className="mr-1">Uploaded Records</p>
          </div>
        ),
        dataIndex: "uploadedRecords",
        render: (uploadedRecords: string) => {
          return (
            <div className="flex flex-row items-center">
              <div className="flex flex-row">
                <h1 className="ml-2">{uploadedRecords}</h1>
              </div>
            </div>
          );
        },
      },
      {
        title: (
          <div className="flex items-center relative h-5 p-2 leading-3">
            <p className="mr-1">Success Records</p>
          </div>
        ),
        dataIndex: "successRecords",
        render: (successRecords: string) => {
          return (
            <div className="flex flex-row items-center">
              <div className="flex flex-row">
                <h1 className="ml-2">{successRecords}</h1>
              </div>
            </div>
          );
        },
      },
      {
        title: (
          <div className="flex items-center relative h-5 p-2 leading-3">
            <p className="mr-1">Error Records</p>
          </div>
        ),
        dataIndex: "errorRecords",
        render: (errorRecords: string) => {
          return (
            <div className="flex flex-row items-center">
              <div className="flex flex-row">
                <h1 className="ml-2">{errorRecords}</h1>
              </div>
            </div>
          );
        },
      },
      {
        title: (
          <div className="flex items-center relative h-5 p-2 leading-3">
            <p className="mr-1">Uploaded File</p>
          </div>
        ),
        dataIndex: "uploadedFileLocation",
        render: (uploadedFileLocation: string, data: any) => {
          if (!uploadedFileLocation) {
            return;
          }
          return (
            <div className="flex flex-row items-center">
              <div className="flex flex-row">
                <h1
                  className="ml-2"
                  onClick={() => {
                    window.location.href =
                      process.env.REACT_APP_API_BASE_URL +
                      `/fp-static/downloadDataImportFile?dataImportId=${data?.dataImportId}&fileToDownload=uploadedFileLocation`;
                  }}
                >
                  <p className="ml-1 sm:ml-2 text-xs font-semibold text-primary cursor-pointer">
                    DOWNLOAD
                  </p>
                </h1>
              </div>
            </div>
          );
        },
      },
      {
        title: (
          <div className="flex items-center relative h-5 p-2 leading-3">
            <p className="mr-1">Success File</p>
          </div>
        ),
        dataIndex: "successFileLocation",
        render: (successFileLocation: string, data: any) => {
          if (!successFileLocation) {
            return;
          }
          return (
            <div className="flex flex-row items-center">
              <div className="flex flex-row">
                <h1
                  className="ml-2"
                  onClick={() => {
                    window.location.href =
                      process.env.REACT_APP_API_BASE_URL +
                      `/fp-static/downloadDataImportFile?dataImportId=${data?.dataImportId}&fileToDownload=successFileLocation`;
                  }}
                >
                  <p className="ml-1 sm:ml-2 text-xs font-semibold text-primary cursor-pointer">
                    DOWNLOAD
                  </p>
                </h1>
              </div>
            </div>
          );
        },
      },
      {
        title: (
          <div className="flex items-center relative h-5 p-2 leading-3">
            <p className="mr-1">Error File</p>
          </div>
        ),
        dataIndex: "errorFileLocation",
        render: (errorFileLocation: string, data: any) => {
          if (!errorFileLocation) {
            return;
          }
          return (
            <div className="flex flex-row items-center">
              <div className="flex flex-row">
                <h1
                  className="ml-2"
                  onClick={() => {
                    window.location.href =
                      process.env.REACT_APP_API_BASE_URL +
                      `/fp-static/downloadDataImportFile?dataImportId=${data?.dataImportId}&fileToDownload=errorFileLocation`;
                  }}
                >
                  <p className="ml-1 sm:ml-2 text-xs font-semibold text-primary cursor-pointer">
                    DOWNLOAD
                  </p>
                </h1>
              </div>
            </div>
          );
        },
      },
    ].filter((column: any) => {
      if (!importType || column?.dataIndex !== "templateId") {
        return column;
      }
    });
    function onChange(pagination: any, filters: any, sorter: any, extra: any) {
      let appliedFilters = importType ? `templateIdList=${importType}` : "";
      if (filters.dataImportId) {
        if (!appliedFilters) {
          appliedFilters += `dataImportId=${filters.dataImportId}`;
        } else {
          appliedFilters += `&dataImportId=${filters.dataImportId}`;
        }
      }
      if (filters.fileName) {
        if (!appliedFilters) {
          appliedFilters += `fileName=${filters.fileName}`;
        } else {
          appliedFilters += `&fileName=${filters.fileName}`;
        }
      }
      if (filters.templateId) {
        if (!appliedFilters) {
          appliedFilters += `templateIdList=${
            importType ?? filters.templateId
          }`;
        } else {
          appliedFilters += `&templateIdList=${
            importType ?? filters.templateId
          }`;
        }
      }
      if (filters.statusId) {
        if (!appliedFilters) {
          if (filters?.statusId.length > 0) {
            const joinedStr = filters?.statusId.join(" OR ");
            appliedFilters += `queryString=statusId: ${joinedStr}`;
          }
        } else {
          if (filters?.statusId.length > 0) {
            const filtersArr = appliedFilters.split("&");
            const index = filtersArr.findIndex((item: any) =>
              item.includes("queryString")
            );
            const joinedStr = filters?.statusId.join(" OR ");
            if (index > -1) {
              let extractedQuery = filtersArr[index];
              extractedQuery += ` AND statusId: ${joinedStr}`;
              filtersArr[index] = extractedQuery;
              const joinedFilters = filtersArr.join("&");
              appliedFilters = joinedFilters;
            } else {
              appliedFilters += `&queryString=statusId: ${joinedStr}`;
            }
          }
        }
      }
      setFilteredInfo(filters);
      setActiveAppliedFilters(appliedFilters);
      let outputStr = "";
      setSortData("");
      if (Object.keys(sorter)?.length > 0) {
        setSortedInfo({
          [sorter?.field]: sorter?.field,
          [`${sorter?.field}Type`]: sorter?.order,
        });
        Object.keys(sorter).forEach((item: any) => {
          if (item === "order" && sorter[item] === "ascend") {
            if (outputStr) {
              if (outputStr.includes(sorter["field"])) {
                // do nothing
              } else {
                outputStr += `,${sorter["field"]}`;
              }
            } else {
              outputStr = `${sorter["field"]}`;
            }
          } else if (item === "order" && sorter[item] === "descend") {
            if (outputStr) {
              if (outputStr.includes(sorter["field"])) {
                // do nothing
              } else {
                outputStr += `,-${sorter["field"]}`;
              }
            } else {
              outputStr = `-${sorter["field"]}`;
            }
          }
        });
      }
      if (Array.isArray(sorter)) {
        console.log("inside the 884 condition ", sorter);
        let modifiedObj = {};
        sorter.forEach((item: any) => {
          modifiedObj = {
            ...modifiedObj,
            [item?.field]: item?.field,
            [`${item?.field}Type`]: item?.order,
          };
        });
        console.log("modifiedObj------->", modifiedObj);
        setSortedInfo(modifiedObj);
      }
      sorter &&
        sorter.length > 0 &&
        sorter?.forEach((item: any) => {
          if (item.order === "ascend") {
            if (outputStr) {
              if (outputStr.includes(item["field"])) {
                // do nothing
              } else {
                outputStr += `,${item.field}`;
              }
            } else {
              outputStr = `${item.field}`;
            }
          } else {
            if (outputStr) {
              if (outputStr.includes(item["field"])) {
                // do nothing
              } else {
                outputStr += `,-${item.field}`;
              }
            } else {
              outputStr = `-${item.field}`;
            }
          }
        });
      setSortData((prev: any) => {
        if (prev) {
          return prev + `,${outputStr}`;
        } else {
          return outputStr;
        }
      });

      let prevOrderListingValue = {
        orderByFields: outputStr,
        appliedFilters,
        sortedInfo: {
          [sorter?.field]: sorter?.field,
          [`${sorter?.field}Type`]: sorter?.order,
        },
        filteredInfo: filters,
      };
      localStorage.setItem(
        "prevAppliedFilter",
        JSON.stringify(prevOrderListingValue)
      );
      if (appliedFilters) {
        nextSetOfProds(0, outputStr, appliedFilters, true, pageSize);
      } else if (recordCount) {
        nextSetOfProds(0, outputStr, "", true, pageSize);
      }
    }

    return (
      <div className={!importType ? "h-screen" : ""}>
        <Loader
          isLoading={showLoader && isLoading}
          styles={{
            overlay: (base: any) => ({
              ...base,
              backgroundColor: "white",
            }),
          }}
        >
          {/* <div className="search-prod w-full ltmob:w-4/6 mdlarge:w-3/5 mdxlarge:w-2/5 mb-8">
          <span className="search-icon">
            <SearchOutlined style={{ fontSize: 17 }} />
          </span>
          <input
            placeholder="Search Imports"
            defaultValue={searchedQuery}
            value={searchedQuery}
            className="border border-gray-400 px-2 py-2 w-full rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light"
            onKeyPress={async (e: any) => {
              if (e.key === "Enter") {
                setIsLoading(true);
                setIsSearch("Searched");
                await dispatch(
                  getImportListing({
                    setgetImportListingDetail,
                    setMaxPageIndex,
                    setRecordCount,
                    pageIndex: 0,
                    queryString: e.target.value,
                    uploadedOn_from: selectedFromDate
                      ? selectedFromDate
                      : fromDate
                      ? fromDate
                      : "",
                    uploadedOn_thru: selectedThruDate
                      ? selectedThruDate
                      : thruDate
                      ? thruDate
                      : "",
                    statusId,
                    facilityId: facilityId ? facilityId : "",
                    appliedFilters: activeAppliedFilters,
                  })
                );
                setSearchedQuery("");
                setSearchableString(e.target.value);
                setIsLoading(false);
              }
            }}
            onChange={async (e: any) => {
              setSearchedQuery(e.target.value);
            }}
          />
        </div> */}
          <div className="bg-white p-5 pb-0.5 rounded-lg shadow-sm">
            <div className="flex flex-row justify-between">
              <div className="ltmob:flex ltmob:flex-row m-3 ltmob:items-center">
                <div className="flex flex-row items-center">
                  {false && (
                    <button
                      onClick={async () => {
                        setResetInputField(true);
                        setIsTableLoading(true);
                        await dispatch(
                          getImportListing({
                            setgetImportListingDetail,
                            setMaxPageIndex,
                            orderByFields: sortData,
                            setRecordCount,
                            pageIndex: 0,
                            // searchString: orderStatus,
                            uploadedOn_from: fromDate ? fromDate : "",
                            uploadedOn_thru: thruDate ? thruDate : "",
                            statusId,
                            queryString,
                            facilityId: facilityId ? facilityId : "",
                            appliedFilters: importType
                              ? `templateIdList=${importType}`
                              : "",
                            shipmentId,
                          })
                        );
                        localStorage.removeItem("importListingFromDate");
                        localStorage.removeItem("importListingThruDate");
                        setActiveAppliedFilters(
                          importType ? `templateIdList=${importType}` : ""
                        );
                        setSelectedFromDate("");
                        setSelectedThruDate("");
                        setDatePickerFrom("");
                        setDatePickerThru("");
                        setFilteredInfo({});
                        setIsTableLoading(false);
                      }}
                      className="mb-2 px-8 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-2 rounded-lg"
                    >
                      Clear Filter
                    </button>
                  )}
                  <button
                    onClick={async () => {
                      setIsTableLoading(true);
                      await dispatch(
                        getImportListing({
                          setgetImportListingDetail,
                          setMaxPageIndex,
                          orderByFields: "",
                          setRecordCount,
                          pageIndex: currIndex * 1 - 1,
                          pageSize,
                          // searchString: orderStatus,
                          uploadedOn_from: selectedFromDate
                            ? selectedFromDate
                            : fromDate
                            ? fromDate
                            : "",
                          uploadedOn_thru: selectedThruDate
                            ? selectedThruDate
                            : thruDate
                            ? thruDate
                            : "",
                          statusId,
                          queryString,
                          facilityId: facilityId ? facilityId : "",
                          appliedFilters: activeAppliedFilters,
                          shipmentId,
                        })
                      );
                      setSortedInfo({});
                      setSortData("");
                      setIsTableLoading(false);
                    }}
                    className="ml-4 mb-2 px-8 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-2 rounded-lg"
                  >
                    Clear Sorter
                  </button>
                </div>
              </div>
            </div>

            <Table
              rowKey="shipMethod"
              dataSource={getImportListingDetail}
              columns={columns}
              isServerRendered={recordCount ? true : false}
              isPaginated={true}
              loadNextProds={loadNextProds}
              recordCount={recordCount}
              onChange={onChange}
              loading={isTableLoading}
              resetInputField={resetInputField}
            />
          </div>
        </Loader>
      </div>
    );
  }
);

export default ImportListing;
